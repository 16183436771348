<template>
  <div>
    <NavBar :previewImageUpdated="previewImage" />
    <div class="container rounded bg-white mt-5 mb-5">
      <div class="row">
        <div class="col-md-3 border-right">
          <div class="d-flex flex-column align-items-center text-center p-3 py-5">
            <Spinner v-if="isLoading" />
            <div v-if="!isLoading" :class="{ imagePreviewWrapper: previewImage }"
              :style="{ 'background-image': `url(${previewImage})` }" @click="selectImage" class="rounded profile-pic">
              <div class="d-flex align-items-center justify-content-center profile-pic-upload">
                <i class="fa-solid fa-cloud-arrow-up profile-pic-upload-icon"></i>
              </div>
            </div>
            <span class="fw-bold mt-2">{{ companyName }}</span>
            <span>{{ email }}</span>
            <span class="text-black-50 mb-3">{{ organisationNr }}</span>
            <input class="form-control" ref="fileInput" type="file" @input="pickFile" accept="image/png, image/jpeg" />
          </div>
        </div>
        <div class="col-md-8 border-right">
          <div class="p-3 py-5">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h4 class="text-right">{{ $t('general.profile') }}</h4>
            </div>
            <div class="row mt-2">
              <div class="col-md-12">
                <label class="labels">{{ $t('general.title') }}</label>
                <input type="text" class="form-control" v-model="title" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <label class="labels">{{ $t('auth.firstname') }}</label>
                <input type="text" class="form-control" v-model="firstName" />
              </div>
              <div class="col-md-6">
                <label class="labels">{{ $t('auth.lastname') }}</label>
                <input type="text" class="form-control" v-model="lastName" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <label class="labels">{{ $t('auth.phonenumber') }}</label>
                <input type="tel" class="form-control" v-model="phoneNr" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <label class="labels">{{ $t('auth.companyaddress') }}</label>
                <input type="text" class="form-control" v-model="companyAddress" />
              </div>
              <div class="col-md-6">
                <label class="labels">{{ $t('auth.postalcode') }}</label>
                <input type="text" class="form-control" v-model="companyZip" />
              </div>
              <div class="col-md-6">
                <label class="labels">{{ $t('auth.city') }}</label>
                <input type="text" class="form-control" v-model="companyState" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6 mb-2">
                <Button @click="onEditProfileClick" class="btn btn-primary w-100" type="button"
                  :loading="updatingButton">{{ $t('auth.saveprofile') }}</Button>
              </div>
              <div class="col-md-6">
                <Button @click="resetPassword" class="btn btn-dark w-100" type="button" :loading="sendingResetLink">{{
                  $t('auth.changepassword')
                }}</Button>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-6"></div>
              <div v-if="resetMessage" class="col-md-6 text-center">
                <p>{{ $t('auth.passwordresetlinksent') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { auth, getMerchantUserProfile, editMerchantProfile } from '@/firebase'
import Spinner from '@/components/misc/Spinner.vue'
import Button from '@/components/misc/Button.vue'

export default {
  name: 'Profile',
  components: { NavBar, Spinner, Button },
  data() {
    return {
      email: '',
      subscription: '',
      title: '',
      firstName: '',
      lastName: '',
      companyName: '',
      organisationNr: '',
      phoneNr: '',
      companyAddress: '',
      companyZip: '',
      companyState: '',
      isModalVisible: false,
      previewImage: '../assets/logo.png',
      photoData: null,
      oldPhotoData: null,
      resetMessage: false,
      updatingButton: false,
      sendingResetLink: false,
      isLoading: true,
    }
  },
  async mounted() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userInfo = await getMerchantUserProfile(user.uid)
        this.email = userInfo.email
        this.subscription = userInfo.subscription
        this.title = userInfo.title
        this.firstName = userInfo.firstName
        this.lastName = userInfo.lastName
        this.companyName = userInfo.companyName
        this.organisationNr = userInfo.organisationNr
        this.phoneNr = userInfo.phoneNr
        this.companyAddress = userInfo.address.companyAddress
        this.companyZip = userInfo.address.companyZip
        this.companyState = userInfo.address.companyState
        this.photoData = userInfo.photoData
        this.oldPhotoData = userInfo.photoData
        this.isLoading = false
        // If image isnt uploaded place default.
        if (userInfo.photoData) {
          this.previewImage = userInfo.photoData
        } else {
          this.previewImage =
            'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
        }
      }
    })
  },
  methods: {
    async onEditProfileClick() {
      try {
        this.updatingButton = true
        const address = {
          companyAddress: this.companyAddress,
          companyZip: this.companyZip,
          companyState: this.companyState,
        }

        const updateRes = await editMerchantProfile({
          photoData: this.photoData,
          oldPhotoData: this.oldPhotoData,
          email: this.email,
          subscription: this.subscription,
          title: this.title,
          firstName: this.firstName,
          lastName: this.lastName,
          companyName: this.companyName,
          organisationNr: this.organisationNr,
          phoneNr: this.phoneNr,
          address,
        })

        if (updateRes === 0) {
          this.updatingButton = true
        } else {
          this.updatingButton = false
        }
      } catch (error) {
        console.log('Something went wrong with updating the profile: ', error)
      }
    },
    selectImage() {
      this.$refs.fileInput.click()
    },
    pickFile() {
      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader()
        reader.onload = (e) => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
        this.photoData = file[0]
      }
    },
    async resetPassword() {
      this.sendingResetLink = true;
      await this.$store.dispatch('resetPassword', { email: this.email })
      this.resetMessage = true;
      this.sendingResetLink = false;
    },
  },
}
</script>

<style scoped>
.imagePreviewWrapper {
  width: 150px;
  height: 150px;
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.profile-pic {
  overflow: hidden;
}

.profile-pic-upload {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  opacity: 0;
  transition: all 0.2s;
}

.profile-pic:hover .profile-pic-upload {
  opacity: 1;
}

.profile-pic-upload-icon {
  font-size: 24px;
}

.remove-profile-pic {
  font-size: 14px;
}
</style>
